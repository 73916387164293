import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Paper from '@mui/material/Paper';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';

import { makeStyles } from '@mui/styles';


const BannersGrid = ({ bannerList, itemSelected, gridSize, bannerDelete, bannerSelected }) => {
  const classes = useStyles();
  const imageKeys = Object.keys(bannerList);
  const bannerValues = Object.values(bannerList);

  return (
      <Grid
        item
        xs={gridSize}

      style={{
        paddingTop:"10px"
      }}
      >

        {bannerValues.length > 0 ?
        <Box style={{height: 575, overflow: 'auto'}} marginBottom={2}> 
        {bannerValues.map((data, index) => 
        <div 
        key={`banner${index}`}
        style={{
          display: 'inline-block',
          position: 'relative', 
          paddingTop: '10px'
        }}>
          <HighlightOffIcon
            style={{
              position: "absolute",
              right: -10,
              top: -2,
              backgroundColor: "#fff",
              borderRadius: "50%",
              boxShadow: "1px 1px 2px #999",
              cursor: "pointer",
              zIndex: 100
            }}
            onClick={bannerDelete(imageKeys[index])}
          />
          <Paper 
              onClick={bannerSelected({key: imageKeys[index], value: data})}
              key={index}
              className={classes.hover}
              style={{
                width: 200,
                height: 200,
                marginBottom: 50,
                marginLeft: 30,
                display: "inline-block",
                backgroundImage: `url(${data.imageUrl})`,
                backgroundPosition: "center",
                backgroundSize: "cover", 
                cursor: "pointer",
                border: itemSelected === data.imageUrl ? "5px solid #3f51b5" : "0px"
              }}
            />
        </div>  
        )}
        </Box>
        :  
        <Box style={{padding: "32px", textAlign: "center", width: "100%", color: "#666" }}> <BrokenImageIcon /> <br />Banner gallery is empty.</Box> 
      }
        
      </Grid> 
  );
}
 
const useStyles = makeStyles({
  hover: {
    transition: "transform .3s",
    '&:hover': {
      transform: "scale(1.08)",
      transition: "transform .3s"
    }
  }
})

export default BannersGrid;