import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button, Divider, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import React from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  outline: 'none',
  borderRadius: 2,
  width: 350
};

const ModalFirstTime = ({ open, handleClose }) => {
  return (
    <Modal
      hideBackdrop
      sx={{
        background:
          'linear-gradient(256.42deg, rgba(22, 28, 36, 0.4) 0%, rgba(22, 28, 36, 0.192) 91.29%)'
      }}
      open={open}
      aria-labelledby="modal-modal-title"
    >
      <Box
        sx={{
          ...style
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: 2,
            boxShadow: '-20px 20px 40px rgba(145, 158, 171, 0.12)',
            borderRadius: 2
          }}
        >
          <Box px={8}>
            <img
              alt="trial start"
              src="/static/illustrations/illustration_trial_start.png"
            />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            textAlign="center"
          >
            Welcome dear Guest
          </Typography>
          <Typography textAlign="center" color="primary" fontSize={14}>
            Today starts Your Free Trial
            <br />
            Try SignPro for free during 30 days
          </Typography>
          <Typography
            color="text.secondary"
            display="flex"
            flexDirection="column"
            alignContent="center"
            noWrap
            fontSize={14}
            p={1.75}
          >
            <Box display="flex" alignItems="center" gap={0.5}>
              <CheckCircleOutlineIcon fontSize="small" /> Design beautiful
              signatures
            </Box>
            <Box display="flex" alignItems="center" gap={0.5}>
              <CheckCircleOutlineIcon fontSize="small" /> Add clickable banners
            </Box>
            <Box display="flex" alignItems="center" gap={0.5}>
              <CheckCircleOutlineIcon fontSize="small" /> Apply signatures in
              just few clicks
            </Box>
          </Typography>
          <Divider sx={{ mx: 2, backgroundColor: 'text.secondary' }} />
          <Typography textAlign="center" color="primary" fontSize={14} mt={1}>
            After Free Trial ends, subscribe for <br />
            €0.89 monthly / per user
          </Typography>
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            size="large"
            sx={{ textTransform: 'none', my: 1.5, mx: 'auto' }}
          >
            Continue to SignPro
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

ModalFirstTime.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default ModalFirstTime;
