import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import * as Gapi from '../../Helpers/Gapi';
import * as SettingsReducer from '../../Reducers/Settings';

const Preview = ({
  type,
  data,
  dispatch,
  onSaveNewDataSuccess
}) => {
  const [bannerUrl, setBannerUrl] = useState('');
  const [disclaimerTitle, setDisclaimerTitle] = useState('');
  const [disclaimerValue, setDisclaimerValue] = useState('');
  const [inputError, setInputError] = useState({'bannerUrl': false, 'disclaimerTitle': false, 'disclaimerValue': false});
  const [actionDisabled, setActionDisabled] = useState(true);

  const checkError = (data) => {
    if (type === 'banner')
      setInputError({...inputError, ...{'bannerUrl': data.link === ''}});
    if (type === 'disclaimer')
      setInputError({...inputError, ...{'disclaimerTitle': data.title === ''}, ...{'disclaimerValue': data.text === ''}});
  }

  const saveNewData = () => {
    if (type === 'banner') {
      checkError(bannerUrl);
      if(bannerUrl !== '') {
        let bannerPayload = data
        bannerPayload.value.link = bannerUrl
        Gapi.saveBanner(bannerPayload.value).then((banner) => {
          SettingsReducer.addBanner(dispatch)(banner);
          onSaveNewDataSuccess();
        }).catch((err) => console.error(err))
      }
    }

    if (type === 'disclaimer') {
      const disclaimer = {title: disclaimerTitle, text: disclaimerValue};
      checkError(disclaimer);
      if (disclaimerTitle !== '' && disclaimerValue !== '') {
        Gapi.saveDisclaimer(disclaimer).then((disclaimer) => {
          SettingsReducer.addDisclaimer(dispatch)(disclaimer);
          onSaveNewDataSuccess();
        }).catch((err) => {
          console.error(err);
        })
      }
    }
  }

  const overwriteData = () => {
    if (type === 'banner') {
      checkError(bannerUrl);
      if(bannerUrl !== '') {
        let bannerPayload = data
        bannerPayload.value.link = bannerUrl
        console.log('preview status ', data)
        console.log("banner", bannerUrl)
        Gapi.updateBanner({key: bannerPayload.key, banner: bannerPayload.value}).then(() => {
          SettingsReducer.updateBanner(dispatch)(data.key, bannerPayload.value);
        }).catch((err) => {
          console.error(err);
        })
        
      }
    }

    if (type === 'disclaimer') {
      const disclaimer = {title: disclaimerTitle, text: disclaimerValue};
      checkError(disclaimer);
      Gapi.updateDisclaimer({key: data.key, disclaimer: disclaimer}).then(() => {
        SettingsReducer.updateDisclaimer(dispatch)(data.key, disclaimer);
      }).catch((err) => {
        console.error(err);
      })
    }
  }

  useEffect(() => {
    setActionDisabled(true);
    if (type === 'banner')
      setBannerUrl(data.value.link);
    if (type === 'disclaimer')
    {
      setDisclaimerTitle(data.value.title);
      setDisclaimerValue(data.value.text);
    }
  }, [type, data]);

  const imagePreviewBackground = type === 'banner' ? data.value.imageUrl : data.value


  const imagePreview = <div
    style={{
      width: '100%',
      height: 450,
      marginBottom: 20,
      backgroundImage: `url(${imagePreviewBackground})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    }}
  />

  const urlInput = <TextField
    error={inputError.bannerUrl}
    value={bannerUrl}
    label='url'
    onChange={(e) => {
      setBannerUrl(e.target.value);
      setActionDisabled(false);
    }}
    helperText={inputError.bannerUrl && "Can't be blank"}
    style={{width: 400, marginBottom: 35}}
  />

  const disclaimerChange = <>
    <Box marginBottom={6} textAlign='left' width='40%'>
      <TextField
        error={inputError.disclaimerTitle}
        value={disclaimerTitle}
        label='title'
        onChange={(e) => {
          setDisclaimerTitle(e.target.value);
          setActionDisabled(false);
        }}
        helperText={inputError.disclaimerTitle && "Can't be blank"}
        style={{width: '100%'}}
      />
    </Box>
    <Box marginBottom={4}>
      <TextField
        error={inputError.disclaimerValue}
        value={disclaimerValue}
        onChange={(e) => {
          setDisclaimerValue(e.target.value);
          setActionDisabled(false);
        }}
        multiline
        rows={16}
        variant="outlined"
        label="value"
        style={{width: '100%'}}
        helperText={inputError.disclaimerValue && "Can't be blank"}
      />
    </Box>
  </>

  const saveActions = <Grid container direction='row' justify='flex-end' alignItems='center'>
    <Button variant="contained" disabled={actionDisabled} color="primary" style={{marginRight: 12}} onClick={saveNewData}>
      Save as new
    </Button>
    <Button variant="contained" disabled={actionDisabled} color="primary" onClick={overwriteData}>
      Overwrite
    </Button>
  </Grid>

  return (
    <div style={{textAlign: 'center'}}>
      <Box marginBottom={4}>
        <h1>Preview</h1>
      </Box>
      {type === 'image' ?
        imagePreview
        : type === 'banner' ? (
            <>
              {urlInput}
              {imagePreview}
              {saveActions}
            </>
          )
          :
          <>
            {disclaimerChange}
            {saveActions}
          </>
      }
    </div>
  );
}

export default Preview;