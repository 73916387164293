import React from 'react';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

const DisclaimersList = ({ disclaimerList, gridSize, disclaimerSelected, disclaimerDelete }) => {

    const classes = useStyles();

    return ( 
    <Grid
        item
        xs={gridSize}
      >
      <Box marginBottom={2}>
        <Table>
          <TableHead style={{backgroundColor: '#4a4a4a'}}>
            <TableRow>
              <TableCell colSpan="2" style={{color: 'white' }}>
                DISCLAIMERS
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {disclaimerList && Object.keys(disclaimerList).map((data, index) => (
              <TableRow className={classes.row} key={index} style={{cursor: 'pointer'}}>
                <TableCell style={{ width: "100%" }} component='td' onClick={disclaimerSelected({key: data, value: Object.values(disclaimerList)[index]})}>
                  { disclaimerList[data].title }
                </TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={disclaimerDelete(data)} >
                    <CloseIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      
    </Grid> );
}

const useStyles = makeStyles({
    row: {
      backgroundColor: "#fff",
      '&:hover': {
        backgroundColor: "#eee"
      }   
    },
})
 
export default DisclaimersList;