import { DialogTitle, Divider, Typography, IconButton, DialogContent, Stack, DialogActions, Container } from '@mui/material';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import React from 'react';
import { DialogAnimate } from '../../../../components/animate';
import Label from '../../../../components/Label';
import { LoadingButton } from '@mui/lab';
import Scrollbar from '../../../../components/Scrollbar';
import EmptyContent from '../../../../components/EmptyContent';


const SelectorListDialog = ({open, handleClose, label, rows, loading, selectedValuePosition, onApply, captionText}) => {
    return (
      <DialogAnimate maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle sx={{pb: 3}}>
            <Stack direction="row" justifyContent="space-between">
                {label}
                <IconButton onClick={handleClose}>
                    <Icon icon={closeFill} />
                </IconButton>
            </Stack>
        </DialogTitle>
        <DialogContent sx={{p: 3, overflow: "auto"}}>
            <Stack spacing={3}>
                {rows && rows.length > 0 ? rows.map((row, key) =>
                    <Stack key={`stack-${key}`} direction="row" justifyContent="space-between" alignItems="center">
                        <Stack direction="row" alignItems="center" spacing={2}>
                            { row.image && <img style={{height: "42px", width: "42px", objectFit: "cover", borderRadius: "10px"}} src={row.image} alt="SignPro" /> }
                            <Typography variant="subtitle2" noWrap>{row.value}</Typography>
                        </Stack>
                        {key == selectedValuePosition ? 
                            <Label color='success'>
                                Applied
                            </Label>
                            :
                            <LoadingButton
                                key="apply"
                                sx={{minWidth: 84}}
                                loading={loading}
                                variant="contained"
                                onClick={() => onApply(key)}>
                                Apply
                            </LoadingButton>
                        }
                    </Stack>
                ) : <>
                    <EmptyContent title="Empty content" />
                </>}
            </Stack>
        </DialogContent>
        <DialogActions sx={{justifyContent: "start"}}>
            <Typography variant="caption" noWrap sx={{ fontStyle: 'italic' }}>{captionText}</Typography>
        </DialogActions>
      </DialogAnimate>
    );
  };

export default SelectorListDialog;