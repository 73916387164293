import { Box, Button, Container, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { redirectTo } from '../Actions/Navigation';
import { SeverErrorIllustration } from '../assets';
import { MHidden } from '../components/@material-extend';
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';
import { signIn } from '../Helpers/Gapi';
import GoogleSignIn from '../UI/GoogleSignIn';
import NonAdmin from './NonAdmin';
import NonGW from './NonGW';
import NotInstalled from './NotInstalled';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ErrorStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

const SectionStyle = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  background: theme.palette.primary.main,
  padding: theme.spacing(18, 4)
}));

const LogoStyle = styled('div')(({ theme }) => ({
  width: '230px',
  position: 'absolute',
  left: 0,
  top: 0,
  padding: theme.spacing(5, 5)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 550,
  margin: 'auto',
  display: 'flex',
  textAlign: 'center',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const LoginScreen = ({ auth, redirectTo }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [nonAdmin, setNonAdmin] = useState(false);
  const [isGmail, setIsGmail] = useState(false);
  const [notInstalled, setNotInstalled] = useState(false);

  useEffect(() => {
    if (auth.logginError) {
      console.log("auth.logginError", auth.logginError);
      setIsGmail(false);
      setNotInstalled(false);
      setNonAdmin(false);
      if (auth.logginError === 'IS-GMAIL') {
        setIsGmail(true);
      } else if (auth.logginError === 'NOT-INSTALLED') {
        setNotInstalled(true);
      } else if (auth.logginError === 'NON-ADMIN') {
        setNonAdmin(true);
      } else {
        enqueueSnackbar(auth.logginError, { variant: 'info' });
      }
    }
  }, [auth.logginError]);

  return (
    <>
      {auth.isGapiLoaded !== 'idpiframe_initialization_failed' ? (
        <RootStyle title="SignPro">
          <MHidden width="mdDown">
            <Link href="https://signpro.app/" target="_blank">
              <LogoStyle>
                <img src="/static/brand/signpro-white.png" alt="signpro logo" />
              </LogoStyle>
            </Link>
            <SectionStyle>
              <Typography
                variant="h3"
                color="white"
                sx={{ px: 5, mt: 10, mb: 5 }}
              >
                SignPro <br /> build and manage corporate e-mail signatures
              </Typography>
              <Box sx={{ px: 6 }}>
                <img
                  alt="register"
                  src="/static/illustrations/illustration_register.png"
                />
              </Box>
            </SectionStyle>
          </MHidden>

          {isGmail ? (
            <NonGW redirectTo={redirectTo} />
          ) : nonAdmin ? (
            <NonAdmin redirectTo={redirectTo} />
          ) : notInstalled ? (
            <NotInstalled redirectTo={redirectTo} />
          ) : (
            <LoginContainer redirectTo={redirectTo} />
          )}
        </RootStyle>
      ) : (
        <ErrorStyle title="SignPro">
          <Container>
            <MotionContainer initial="initial" open>
              <Box sx={{ maxWidth: 980, margin: 'auto', textAlign: 'center' }}>
                <motion.div variants={varBounceIn}>
                  <Typography variant="h3" paragraph>
                    Dear guest, <br />
                    please unblock third-party cookies in your browser!
                  </Typography>
                </motion.div>
                <Typography sx={{ color: 'text.secondary' }}>
                  Unfortunately, disabled cookies in your browser may block
                  application’s loading or proper functioning. Please enable the
                  cookies or turn off the Incognito Mode, and then try again.{' '}
                  <br />
                  Read more how to clear, enable, and manage cookies in Chrome
                  browser{' '}
                  <Link
                    underline="always"
                    target="_blank"
                    sx={{ color: '#00AB55 !important' }}
                    href="https://support.google.com/chrome/answer/95647?hl=en#zippy=%2Callow-or-block-cookies"
                  >
                    here
                  </Link>
                  .
                </Typography>

                <motion.div variants={varBounceIn}>
                  <SeverErrorIllustration
                    sx={{ height: 260, my: { xs: 5, sm: 10 } }}
                  />
                </motion.div>

                <Button
                  size="large"
                  variant="contained"
                  target="_blank"
                  onClick={() => window.location.reload()}
                >
                  Cookies are enabled, I’m ready to try again
                </Button>
              </Box>
            </MotionContainer>
          </Container>
        </ErrorStyle>
      )}
    </>
  );
};

const LoginContainer = ({ redirectTo }) => {
  const signInWrapper = async () => {
    await signIn();
    redirectTo('/company');
  };

  return (
    <Container>
      <ContentStyle>
        <Box sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h4" gutterBottom>
              Sign in to SignPro with Your Google account
            </Typography>
          </Box>
        </Box>
        <GoogleSignIn sx={{ mx: '20px' }} onClick={signInWrapper} />
        <Typography
          variant="body2"
          align="center"
          sx={{ color: 'text.secondary', mt: 8 }}
        >
          By signing up, I agree to SignPro&nbsp;
          <Link
            underline="always"
            sx={{ color: '#00AB55 !important' }}
            href="https://signpro.app/terms.html"
            target="_blank"
          >
            Terms of Service
          </Link>
          &nbsp;and&nbsp;
          <Link
            underline="always"
            sx={{ color: '#00AB55 !important' }}
            href="https://signpro.app/policy.html"
            target="_blank"
          >
            Privacy Policy
          </Link>
          .
        </Typography>
        <Typography
          variant="body2"
          align="center"
          sx={{ color: 'text.secondary', mt: 1 }}
        >
          Built with love by{' '}
          <Link
            underline="always"
            sx={{
              color: '#20769F',
              textDecorationColor: '#20769F',
              fontWeight: 800
            }}
            href="https://wedoops.io/"
            target="_blank"
          >
            Wedoops
          </Link>{' '}
          team.
        </Typography>
      </ContentStyle>
    </Container>
  );
};

LoginScreen.propTypes = {
  redirectTo: PropTypes.func,
  auth: PropTypes.object
};

LoginContainer.propTypes = {
  redirectTo: PropTypes.func
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ redirectTo }, dispatch);
const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
