import React, { useEffect } from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { bindActionCreators } from 'redux';
import { Icon } from '@iconify/react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { makeStyles } from "@mui/styles";
import { IconButton, styled, Tooltip, tooltipClasses, Typography } from '@mui/material';
import { warnUserChanges } from "../../../Actions/Navigation";

import Column from "./Column";

import { useStateValue } from "../Context";
import { connect } from "react-redux";

const mapStateToProps = ({context}) => ({context});
const mapDispatchToProps = dispatch => bindActionCreators({ warnUserChanges }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(({context, warnUserChanges}) => {
  const classes = useStyles();

  const [{ template }, dispatch] = useStateValue();
  const [isFirstTemplate, setIsFirstTemplate] = React.useState(true);

  const onDragEnd = (result) => {
    const { destination, source, type } = result;

    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    
    if (type === "row") {
      dispatch({
        type: "moveRow",
        row: { destination, source },
      });
    }else if (type.match(/row-(\d+)-columns/) ){
      dispatch({
        type: "moveColumn",
        column: {destination, source}

      })
    } else if (type.match(/column-(\d+)-items/)){
      dispatch({
        type: "moveItem",
        item: { destination, source },
      });
    }
  };

  useEffect(() => {
    if (isFirstTemplate) {
      setIsFirstTemplate(false);
      return;
    }
    if (context && !context.warnUserChanges) warnUserChanges(); 
  }, [template]);

  return (
    <div className={classes.editor}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Rows data={template.rows||[]} />
      </DragDropContext>
      <EmptyRow />
    </div>
  );
});

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#fff",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)"
  },
}));

const Rows = ({ data }) => {
  return (
    <Droppable droppableId={"rows"} type="row">
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          {data.map((row, i) => (
            <Row key={`row-${i}`} data={row} index={i + 1} />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const Row = ({ data, index }) => {
  const classes = useStyles();

  return (
    <Draggable type="row" draggableId={`row-${index}`} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={classes.row}
        >
          <RowContent>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: 32,
                height: "100%",
                color: "#00AB55",
                paddingLeft: "12px"
              }}
              {...provided.dragHandleProps}
            >
              <HtmlTooltip placement="left" title={
                <React.Fragment>
                  <Typography color="primary" variant="subtitle2"><b>Drag the container</b></Typography>
                  <Typography color="text.secondary" variant="caption">
                    Drag and drop the container to change it’s order. <br/>
                    NB! The components are also draggable, but within the container.
                  </Typography>
                </React.Fragment>
              } arrow>
                <Icon icon="material-symbols:open-with-rounded" width="20" height="20" />
              </HtmlTooltip>
            </div>
            <ContentRow data={data} index={index} />
          </RowContent>
          <RowAddColumn index={index} display={!data || !data.columns || data.columns.length < 3} />
        </div>
      )}
    </Draggable>
  );
};

const ContentRow = ({ data, index }) => {
  const classes = useStyles();
  const _width = `${100/data.columns.length}%`;
  return (
    <Droppable
      droppableId={`row-${index}-columns`}
      type={`row-${index}-columns`}
      direction="horizontal"
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={classes.contentRow}
        >
          {data.columns.map((column, i) => (
            <div key={`content-row-${i}`} style={{width: _width}}>
              <Column
                key={i}
                data={column}
                index={index * 100 + i + 1}
                isDragDisabled={data.columns.length <= 1}
              />
            </div>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const EmptyRow = () => {
  const classes = useStyles();
  return (
    <div className={classes.row}>
      <RowAddRow />
    </div>
  );
};

// Wdiget botón para añadir fila
const RowContent = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.rowContent}>{children}</div>;
};

const SingleRowContent = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.singleRowContent}>{children}</div>;
};

const RowAddRow = () => {
  // eslint-disable-next-line no-unused-vars
  const [{ template }, dispatch] = useStateValue();

  const handleClick = () => {
    dispatch({ type: "addRow", row: { id: 0 } });
  };

  return (
    <SingleRowContent>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{padding: 0}}
        color="primary"
      >
        <AddRoundedIcon style={{ fontSize: 26 }} />
      </IconButton>
    </SingleRowContent>
  );
};

// Wdiget botón para añadir columna
const RowAddColumn = ({ index, display }) => {
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const [{ template }, dispatch] = useStateValue();

  const handleClick = () => {
    dispatch({ type: "addColumn", row: { id: index } });
  };

  return (
    <div className={classes.rowAddContent}>
      {display && 
        <HtmlTooltip placement="right" title={
            <React.Fragment>
              <Typography color="primary" variant="subtitle2"><b>Add a new column</b></Typography>
              <Typography color="text.secondary" variant="caption">
                Press to add a new column. <br />
                Max 3 columns in a row.
              </Typography>
            </React.Fragment>
          } arrow>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            sx={{padding: 0, mt: "6px"}}
            color="primary"
          >
            <AddRoundedIcon style={{ fontSize: 26 }} />
          </IconButton>
        </HtmlTooltip>
      }
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  editor: {
    padding: "1em",
    paddingBottom: 0,
  },
  row: {
    background: "white",
    display: "flex",
    marginBottom: "1em",
  },
  col: {
    width: "100%"
  },
  rowMoveHandle: {
    height: "100%",
    cursor: "move",
  },
  rowContent: {
    borderRadius: "8px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    backgroundColor: "rgba(145, 158, 171, 0.08)",
    padding: "12px 0px",
    width: "100%",
    minHeight: 32,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  singleRowContent: {
    borderRadius: "8px",
    backgroundColor: "rgba(145, 158, 171, 0.08)",
    padding: "12px 0px",
    width: "100%",
    minHeight: 32,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rowAddContent: {
    borderRadius: "8px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    backgroundColor: "rgba(145, 158, 171, 0.08)",
    minHeight: 32,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    paddingTop: "12px",
    paddingRight: "12px"
  },
  rowColumn: {
    border: "1px solid black",
  },
  item: {
    borderBottom: "1px solid black",
    padding: 8,
    textAlign: "left",
    minHeight: 50,
    flexGrow: 1,
  },
  contentRow: {
    display: "flex", 
    width: "100%"
  },
}));
