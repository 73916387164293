import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk'
import { Provider } from 'react-redux'
import rootReducer from './Reducers'

import './utils/highlight';
import 'react-quill/dist/quill.snow.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { loadState, saveState } from './stateHandle';

const initialData = loadState();
const store = createStore(rootReducer, initialData, applyMiddleware(thunk));
store.subscribe( function () {
    saveState(store.getState())
  })
ReactDOM.render(
    <HelmetProvider>
        <Provider store={store}>
           <App /> 
        </Provider>
    </HelmetProvider>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();