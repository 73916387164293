import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const AddNewUrl = ({ bannerUrl, setBannerUrl, setBannerUrlError }) => {

    const menuItems = ["https://", "http://", "mailto:"];

    const [protocolSelected, setProtocolSelected] = useState(menuItems[0]);
    const [urlValue, setUrlValue] = useState('');
    const [inputError, setInputError] = useState('');

    const onUrlChange = (inputValue) => {
        console.log("onUrlChange", inputValue)
        setUrlWithProtocol(inputValue)
        let urlWithProtocol = protocolSelected + urlValue
        setBannerUrl(urlWithProtocol)
    }

    const isValidUrl = (url) => {
        console.log("isValidUrl", url)
        return !!/^\S+\.\S+$/.test(url);
    }

    
    const setUrlWithProtocol = (bannerUrl) => {
        menuItems.map(menuItem => {
            if(bannerUrl.includes(menuItem)){
                setProtocolSelected(menuItem);
                bannerUrl = bannerUrl.replace(menuItem, '');
            }
            return null;
        })
        setUrlValue(bannerUrl);
    }

    const urlValidation = () => {
        let urlWithProtocol = protocolSelected + urlValue
        if (isValidUrl(urlWithProtocol)) {
            setInputError('')
            setBannerUrlError(false)
        } else {
            setInputError('This Url is not valid');
            setBannerUrlError(true)
        }
    }

    useEffect(() => {
        setUrlWithProtocol(bannerUrl)
    }, [])

    useEffect(() => {
        urlValue && urlValidation()
    }, [urlValue])

    return ( 
        <Grid
            container>
            <FormControl variant="outlined" /* className={classes.formControl} */ style={{width: "20%"}} >
                <InputLabel id="protocol-selector" style={{marginRight: "23px"}}>Protocol</InputLabel>
                <Select
                    native
                    value={protocolSelected} 
                    onChange={(e) => setProtocolSelected(e.target.value)} 
                    label="Protocol"
                    inputProps={{
                        name: 'protocol',
                        id: 'protocol-selector',
                      }}
                >
                {menuItems.map((menuItem, idx) => (<option key={`add-new-url-${idx}`} value={menuItem}>{menuItem}</option>))}
                </Select>
            </FormControl>
            <Box marginBottom={2} style={{paddingLeft: "24px", width: "80%"}} >
                <TextField
                    style={{width: "100%"}}
                    error={inputError}
                    value={urlValue} 
                    onPaste={(e) => {e.preventDefault(); onUrlChange(e.clipboardData.getData('text'))}}
                    onChange={(e) => onUrlChange(e.target.value)}
                    label="Banner Url"
                    helperText={inputError}
                />
            </Box>
        </Grid> 
  );
}
 
export default AddNewUrl;