import React, { useState, useEffect, useRef } from 'react';

import ConfiguratorAlignWidget from "./Components/ConfiguratorAlignWidget";
import ConfiguratorColorWidget from "./Components/ConfiguratorColorWidget";
import ConfiguratorFontWidget from "./Components/ConfiguratorFontWidget";
import ConfiguratorLineHeightWidget from "./Components/ConfiguratorLineHeightWidget";
import ConfiguratorPaddingWidget from "./Components/ConfiguratorPaddingWidget";
import ConfiguratorNumberWidget from "./Components/ConfiguratorNumberWidget";
import ConfiguratorBorderWidget from "./Components/ConfiguratorBorderWidget";
import ConfiguratorImageWidget from "./Components/ConfiguratorImageWidget";
import ConfiguratorTextSizeWidget from "./Components/ConfiguratorTextSizeWidget";
import ConfiguratorTextStyleWidget from "./Components/ConfiguratorTextStyleWidget";
import ConfiguratorDisclaimerWidget from './Components/ConfiguratorDisclaimerWidget';
import ConfiguratorTextFieldWidget from './Components/ConfiguratorTextFieldWidget';
import { List, ListItem } from '@mui/material';
import ConfiguratorSpacerBorderWidget from './Components/ConfiguratorSpacerBorderWidget ';
import ConfiguratorBorderStyleWidget from './Components/ConfiguratorBorderStyleWidget';

const TextComponentConfig = ({ component, onChange, isDisclaimer }) => {
  const [bgColor, setBgColor] = useState(component.bgColor || "inherit");
  const [textColor, setTextColor] = useState(component.textColor || "inherit");
  const [align, setAlign] = useState(component.align || "left");
  const [lineHeight, setLineHeight] = useState(component.lineHeight || "inherit");
  const [padding, setPadding] = useState(component.padding || "0");
  const [fontFamily, setFontFamily] = useState(component.fontFamily || "inherit");
  const [textSize, setTextSize] = useState(component.textSize || "inherit");
  const [textStyle, setTextStyle] = useState(component.textStyle || '{"fontWeight": "normal", "fontStyle": "inherit", "textDecoration": "none"}');
  const [disclaimer, setDisclaimer] = useState({"title": component.title || "disclaimer", "value": component.value || "{disclaimer}"});
  const [value, setValue] = useState(component.value);
  const [borderWidth, setBorderWidth] = useState(component.borderWidth || "0");
  const [borderColor, setBorderColor] = useState(component.borderColor || "#000");
  const [borderStyle, setBorderStyle] = useState(component.borderStyle || "solid");

 
    useEffect(() => {
      onChange({
        bgColor,
        textColor,
        align,
        lineHeight,
        padding,
        fontFamily,
        textSize,
        textStyle,
        value,
        borderWidth,
        borderColor,
        borderStyle,
        ...(isDisclaimer && {title: disclaimer.title, value: disclaimer.value})
      });
    }, [onChange, bgColor, textColor, align, lineHeight, padding, borderWidth, borderColor, borderStyle, fontFamily, textSize, textStyle, value, disclaimer])

    useEffect(() => {
      setBgColor(component.bgColor || "inherit")
      setAlign(component.align || "left")
      setPadding(component.padding || "0")
      setBorderColor(component.borderColor || "#000")
      setBorderStyle(component.borderStyle || "solid")
      setBorderWidth(component.borderWidth || "0")
      setTextColor(component.textColor || "inherit")
      setValue(component.value)
      setLineHeight(component.lineHeight || "inherit")
      setFontFamily(component.fontFamily || "inherit")
      setTextSize(component.textSize || "inherit")
      setTextStyle(component.textStyle || '{"fontWeight": "normal", "fontStyle": "inherit", "textDecoration": "none"}')
      
    }, [component]);


  return (
    <List style={{ width: "100%" }}>
      {
        isDisclaimer ? (
          <ListItem sx={{paddingY: "4px"}}>
            <ConfiguratorDisclaimerWidget
              label="Disclaimers"
              disclaimerSelected={disclaimer}
              onChange={setDisclaimer}
            />
          </ListItem>
        ) : component.editable ? (
          <ListItem sx={{paddingY: "4px"}}>
            <ConfiguratorTextFieldWidget value={value} onChange={setValue} />
          </ListItem>
        ) : null
      }
      <ListItem sx={{paddingY: "12px"}}>
        <ConfiguratorColorWidget
          label="Text colour"
          value={textColor}
          onChange={setTextColor}
        />
      </ListItem>
      <ListItem sx={{paddingY: "12px"}}>
        <ConfiguratorColorWidget
          label="Background colour"
          value={bgColor}
          onChange={setBgColor}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorFontWidget
          label={"Font"}
          value={fontFamily}
          onChange={setFontFamily}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorTextSizeWidget
          label={"Text size"}
          value={textSize}
          onChange={setTextSize}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorTextStyleWidget
          label={"Text style"}
          value={textStyle}
          onChange={setTextStyle}
        />
      </ListItem>
      <ListItem sx={{paddingY: "10px", flexWrap: "wrap"}}>
        <ConfiguratorLineHeightWidget
          label={"Line height"}
          value={lineHeight}
          onChange={setLineHeight}
          customiseSwitch={true}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorAlignWidget
          label={"Alignment"}
          value={align}
          onChange={setAlign}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorPaddingWidget
          label={"Padding"}
          value={padding}
          onChange={setPadding}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorBorderWidget
          label="Border"
          value={borderWidth}
          onChange={setBorderWidth}
        />
      </ListItem>
      <ListItem sx={{paddingY: "12px"}}>
        <ConfiguratorColorWidget
          label="Border colour"
          value={borderColor}
          onChange={setBorderColor}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorBorderStyleWidget
          label="Border style"
          value={borderStyle}
          onChange={setBorderStyle}
        />
      </ListItem>
    </List>
  );
};
const SpacerComponentConfig = ({ component, onChange }) => {
  const [bgColor, setBgColor] = useState(component.bgColor || "inherit");
  const [width, setWidth] = useState(component.width || 568);
  const [align, setAlign] = useState(component.align || "center");
  const [padding, setPadding] = useState(component.padding || "0px");
  const [border, setBorder] = useState(component.border || "1px solid #ccc");

  useEffect(() => {
    onChange({
      bgColor,
      align,
      padding,
      width,
      border,
    });
  }, [onChange, bgColor, align, padding, width, border]);

  useEffect(() => {
    setBgColor(component.bgColor || "inherit")
    setAlign(component.align || "center")
    setPadding(component.padding || "0px")
    setWidth(component.width || 568)
    setBorder(component.border || "1px solid #ccc")
  }, [component]);

  return (
    <List style={{ width: "100%" }}>
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorColorWidget
          label="Background colour"
          value={bgColor}
          onChange={setBgColor}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorSpacerBorderWidget
          label="Line"
          value={border}
          onChange={setBorder}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorNumberWidget
          label="Width"
          step={5}
          min={0}
          max={568}
          value={width}
          onChange={setWidth}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorAlignWidget
          label={"Alignment"}
          value={align}
          onChange={setAlign}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorPaddingWidget
          label={"Padding"}
          value={padding}
          onChange={setPadding}
        />
      </ListItem>
    </List>
  );
};
const ImageComponentConfig = ({ component, onChange }) => {
  const [bgColor, setBgColor] = useState(component.bgColor || "inherit");
  const [align, setAlign] = useState(component.align || "center");
  const [padding, setPadding] = useState(component.padding || "0");
  const [width, setWidth] = useState(component.width);
  const [value, setValue] = useState(component.value);
  const [borderWidth, setBorderWidth] = useState(component.borderWidth || "0");
  const [borderColor, setBorderColor] = useState(component.borderColor || "#000");
  const [borderStyle, setBorderStyle] = useState(component.borderStyle || "solid");

  const minWidth = useRef(0);
  const maxWidth = useRef(568);

  useEffect(() => {
    onChange({
      bgColor,
      align,
      padding,
      borderWidth,
      borderColor,
      borderStyle,
      width,
      value
    });
  }, [onChange, bgColor, align, padding, borderWidth, borderColor, borderStyle, width, value]);

  const handleChange = (width, value, columnWidth) => {
    let imageWidth = Math.min(width, columnWidth);
    maxWidth.current = imageWidth;
    setWidth(imageWidth);
    setValue(value);
  }

  return (
    <List style={{ width: "100%" }}>
      {component.selectable ? (
        <ListItem sx={{paddingY: "4px"}}>
          <ConfiguratorImageWidget label={component.isBanner ? "Banners" : "Images"} value={value} onChange={handleChange} isBanner={Boolean(component.isBanner)} />
        </ListItem>
      ) : null}
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorNumberWidget label="Image size" value={width} min={minWidth.current} max={maxWidth.current} readOnly={false} onChange={setWidth} />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorAlignWidget
          label={"Alignment"}
          value={align}
          onChange={setAlign}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorColorWidget
          label="Background colour"
          value={bgColor}
          onChange={setBgColor}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorPaddingWidget
          label={"Padding"}
          value={padding}
          onChange={setPadding}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorBorderWidget
          label="Border"
          value={borderWidth}
          onChange={setBorderWidth}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorColorWidget
          label="Border colour"
          value={borderColor}
          onChange={setBorderColor}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorBorderStyleWidget
          label="Border style"
          value={borderStyle}
          onChange={setBorderStyle}
        />
      </ListItem>
    </List>
  );
};

export default ({ component, onChange }) => {
  console.log('COMPONENT', component);
  if (component?.type === "text") {
    return <TextComponentConfig component={component} onChange={onChange} isDisclaimer={false}/>;
  } else if (component?.type === "spacer") {
    return <SpacerComponentConfig component={component} onChange={onChange} />;
  } else if (component?.type === "image") {
    return <ImageComponentConfig component={component} onChange={onChange} />;
  } else if (component?.type === "disclaimer") {
    return <TextComponentConfig component={component} onChange={onChange} isDisclaimer={true} />;
  } 
  return (
    <List>
      <ListItem>Not supported</ListItem>
    </List>
  );
};
