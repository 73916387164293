import { Button, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const GoogleSignIn = ({ children = 'Continue with Google', onClick, sx }) => {
  const theme = useTheme();

  return (
    <Button
      size="large"
      variant="outlined"
      sx={{
        gap: 1,
        border: '1px solid rgba(145, 158, 171, 0.32)',
        borderRadius: '8px',
        color: theme.palette.text.primary,
        textTransform: 'none',
        transition: 'all 0.3s ease',
        '&:hover': {
          // make the text color lighter based on theme.palette.text.primary
          color: theme.palette.text.primary,
          opacity: 0.8,
          border: '1px solid rgba(145, 158, 171, 0.32)',
          backgroundColor: 'rgba(145, 158, 171, 0.08)'
        },
        ...sx
      }}
      onClick={onClick}
    >
      <img
        width="32px"
        height="32px"
        src={`/static/icons/socials/ic_google.png`}
      />
      {children}
    </Button>
  );
};

GoogleSignIn.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  sx: PropTypes.object
};

export default GoogleSignIn;
