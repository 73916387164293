import React, { useEffect } from 'react';
import {Grid, Box, Card} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CompanyDetailsForm from '../UI/CompanyDetailsForm';
import CompanyImageForm from '../UI/CompanyImageForm';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { setCompanyForm, submitCompanyForm }  from '../Actions/Company';
import { warnUserChanges, userConfirmNavigation } from "../Actions/Navigation";
import { useSnackbar } from 'notistack';
import Loading from './Loading';

import * as Gapi from '../Helpers/Gapi';


const onInputChange = (setCompanyForm, companyForm, context, warnUserChanges) =>
  (field, value) => {
    setCompanyForm({
      ...companyForm,
      [field]: value,
    });
    if (context && !context.warnUserChanges) warnUserChanges(); 
  }

const setImageBase64 = (files, onInputChange) => {
  const reader = new FileReader();
  reader.readAsDataURL(files[0]);
  reader.onload = () => {  onInputChange('imageUrl', reader.result); }
  reader.onerror = error => console.error(error);
}

const mapStateToProps = ({context, companyForm}) => ({context, companyForm});
const mapDispatchToProps = dispatch => bindActionCreators({ setCompanyForm, submitCompanyForm, warnUserChanges, userConfirmNavigation }, dispatch);

const CompanyGeneral = connect(mapStateToProps, mapDispatchToProps)(({ context, companyForm, setCompanyForm, submitCompanyForm, warnUserChanges, userConfirmNavigation }) => {  
  
  const setCompanyFormToReducer = onInputChange(setCompanyForm, companyForm, context, warnUserChanges);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => { context.company && setCompanyForm(context.company) }, [context.company, context.domain]);
  useEffect(() => { setCompanyForm(context.company) }, []);
  useEffect(() => {  
    if (!context.navigationAvailable) {
      setCompanyForm({
        companyName: '',
        webpage: '',
        imageUrl: '',
        linkedin: '',
        instagram: '',
        twitter: '',
        facebook: '',
        youtube: '',
        submitting: false,
      })
    } else {
      setCompanyForm(context.company)
    }
  }, [context.navigationAvailable]);

  return (
    <>
      {context.loading ?
        <Loading />
      :
        <>
          <Grid container spacing={3}>
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <Card sx={{ py: 5, px: 3, textAlign: 'center' }}>
                <CompanyImageForm
                  data={companyForm}
                  onChange={setCompanyFormToReducer}
                  onUseGsuiteLogoClick={() => {
                    Gapi.removeCompanyLogo(context.domain.replace('.', '_')).then((company) => {
                      setCompanyForm(company);
                    });
                  }}
                  setImageBase64={(files) => setImageBase64(files, setCompanyFormToReducer)}
                  onUpdateClick={() => submitCompanyForm(companyForm, context.domain, enqueueSnackbar)}
                />
              </Card>
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={6} xs={12}>
              <Card sx={{ py: 5, px: 3, textAlign: 'center' }}>
                <CompanyDetailsForm
                  data={companyForm}
                  onChange={setCompanyFormToReducer}
                />
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  {(!companyForm.companyName || !companyForm.webpage || !companyForm.imageUrl) ? 
                    <LoadingButton  disabled={true} variant="contained">
                      Save
                    </LoadingButton>
                  :
                    <LoadingButton onClick={() => {submitCompanyForm(companyForm, context.domain, enqueueSnackbar); userConfirmNavigation(); }} variant="contained" loading={companyForm.submitting}>
                      Save
                    </LoadingButton>
                  }
                </Box>
              </Card>
            </Grid>
          </Grid>
        </>
      }
    </>
  );
});
export default CompanyGeneral;
