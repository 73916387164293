import React from 'react'
import { Chip, Paper } from '@mui/material';

function FilterChips({filter, onChange}) {

  function deleteDepartmentFilter(department) {
    var _departmentList = filter.departmentList.filter(d => d !== department);
    onChange({...filter, departmentList: _departmentList});
  }

  function deleteJobTitleFilter(jobTitle) {
    var _jobTitleList = filter.jobTitleList.filter(j => j !== jobTitle);
    onChange({...filter, jobTitleList: _jobTitleList});
  }

  function deleteOrgUnitFilter(orgUnit) {
    var _orgUnitList = filter.orgUnitList.filter(o => o !== orgUnit);
    onChange({...filter, orgUnitList: _orgUnitList});
  }

  return (
    <>
        {filter.departmentList.length > 0 && filter.departmentList.map((department, idx) => <Chip key={`department-chip-${idx}`} sx={{ml: 1, mb: 2}} label={department} variant="outlined" color="primary" onDelete={() => deleteDepartmentFilter(department)} />)}
        {filter.jobTitleList.length > 0 && filter.jobTitleList.map((jobTitle, idx) => <Chip key={`job-title-chip-${idx}`} sx={{ml: 1, mb: 2}} label={jobTitle} variant="outlined" color="primary" onDelete={() => deleteJobTitleFilter(jobTitle)} />)}
        {filter.orgUnitList.length > 0 && filter.orgUnitList.map((orgUnit, idx) => <Chip key={`org-unit-chip-${idx}`} sx={{ml: 1, mb: 2}} label={orgUnit} variant="outlined" color="primary" onDelete={() => deleteOrgUnitFilter(orgUnit)} />)}
    </>
  )
}

export default FilterChips

