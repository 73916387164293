import React from 'react';
import Box from '@mui/material/Box';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Paper from '@mui/material/Paper';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { makeStyles } from '@mui/styles';

const ImagesGrid = ({ imageList, itemSelected, imageSelected, imageDelete }) => {
    const imageKeys = Object.keys(imageList);
    const classes = useStyles();
    const imageValues = Object.values(imageList);

    return ( <Box
        p={4}
        style={{
          display: "flex",
          flexWrap: "wrap"
        }}
      >
        
        {imageValues.lenght > 0 ?     
        imageValues.map((data, index) => (
          <div 
            onClick={imageSelected(data)}
            key={`images-grid-${idx}`}
            style={{
                display: 'inline-block',
                position: 'relative'
            }}>
            <HighlightOffIcon
              style={{
                position: "absolute",
                right: -10,
                top: -11,
                backgroundColor: "#fff",
                borderRadius: "50%",
                boxShadow: "1px 1px 2px #999",
                cursor: "pointer",
                zIndex: 100
              }}
              onClick={imageDelete(imageKeys[index])}
            />
            <Paper
              key={index}
              className={classes.hover}
              style={{
                width: 200,
                height: 200,
                marginBottom: 50,
                marginLeft: 30,
                cursor: "pointer",
                display: "inline-block",
                backgroundImage: `url(${data})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                border: (itemSelected && itemSelected) === data ? "5px solid #3f51b5" : "0px"
              }}
            /> 
            
          </div >
        )): <>

        <Box style={{padding: "32px", textAlign: "center", width: "100%", color: "#666" }}> <BrokenImageIcon /> <br />Image gallery is empty.</Box> 

            </>}             
      </Box> );
}

const useStyles = makeStyles({
  hover: {
    transition: "transform .3s",
    '&:hover': {
      transform: "scale(1.08)",
      transition: "transform .3s"
    }
  }
})
 
export default ImagesGrid;