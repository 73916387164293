import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { gapiLoad, setEnqueueSnackbar, setGapiJWT } from './Actions/Auth';

import ThemeConfig from "./theme";
import './App.css';
import TagManager from 'react-gtm-module';
import Screens from './Navigation/Screens'

import RtlLayout from './components/RtlLayout';

import ThemePrimaryColor from './components/ThemePrimaryColor';
import GlobalStyles from './theme/globalStyles';
import Loading from './Screens/Loading';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers';
import NotistackProvider from './components/NotiStackProvider'

import Login           from './Screens/Login';

const GuardZone = ({auth}) =>{    
  const { enqueueSnackbar } = useSnackbar();
  if(auth.jwt) {  
    setGapiJWT(auth.jwt);  // its needed because jwt inside GAPI not depends of redux state, when state is loaded from localStorage jwt is not setted in gapi. this workaround force it.
    setEnqueueSnackbar(enqueueSnackbar); // for future uses,
  }
  return auth.jwt ? <Screens /> : <Login auth={auth} />
}

const AppBase = ({auth}) =>  {
  return (auth.loading ? <Loading></Loading> : <GuardZone auth={auth}></GuardZone>)
}
  

      //auth.isSignedIn ? "logeao" : "no logeao";
    // ( <Navigation isSignedIn={auth.isSignedIn} /> );


const mapStateToProps = ({auth, context}) => ({auth, context});
const mapDispatchToProps = dispatch => bindActionCreators({ gapiLoad }, dispatch);


const App = ({auth, gapiLoad}) => {
  useEffect(() => {
    gapiLoad();
    if (process.env.GTM_ACTIVE == 'true') {
      TagManager.initialize({ gtmId: 'GTM-KX3NX73' });
    }
  },[]);
  return(
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <NotistackProvider>
            <GlobalStyles />
              <AppBase auth={auth}/>
            </NotistackProvider>
          </LocalizationProvider>
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
